<template>
  <small class="text-danger">
    <slot></slot>
  </small>
</template>

<script>
export default {
  name: 'FieldErrorText',
};
</script>

<style></style>
