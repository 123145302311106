<template>
  <rebalancing-template>
    <template v-slot:chart>
      <echart-doughnut :dataSeries="factChartData" />
    </template>

    <template v-slot:header>
      <div class="mb-2 d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="edit"
          size="sm"
        >
          <feather-icon icon="Edit3Icon" class="mr-25" />
          <span>Изменить</span>
        </b-button>
      </div>
    </template>

    <template v-slot:default>
      <template v-if="items.length == 0">
        <span>Отсутствуют распределенные активы</span>
      </template>
      <template v-else>
        <div class="mb-1" v-for="item in items" :key="item.security_code">
          <b-media no-body>
            <b-media-aside>
              <b-avatar rounded size="36" variant="light-primary">
                <feather-icon size="18" icon="InfoIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="align-self-center my-auto">
                {{ item.security_name }}
              </h6>
              <span class="font-small-2 text-muted">
                {{ item.security_code }}
              </span>
            </b-media-body>
            <div class="d-flex flex-column">
              <span>{{ percentFormatter(item.cur_share) }}</span>
              <span class="align-self-end font-small-2 text-muted">{{
                percentFormatter(item.target_share)
              }}</span>
            </div>
          </b-media>
        </div>
      </template>
    </template>
  </rebalancing-template>
</template>

<script>
import { ref, watch, onMounted } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";

import RebalancingTemplate from "@/components/investments/rebalancing/RebalancingTemplate.vue";
import EchartDoughnut from "@/components/charts/EchartDoughnut.vue";
import usePortfolio from "@/comp-functions/usePortfolio";
import { rebalancingItems } from "@/api/investments";
import { percentFormatter } from "@/utils/formatter";

export default {
  name: "RebalancingInfoCard",
  directives: {
    Ripple,
  },
  components: {
    RebalancingTemplate,
    EchartDoughnut,
  },
  methods: {
    edit() {
      this.$emit("edit");
    },
    percentFormatter(value) {
      return percentFormatter(value);
    },
  },
  setup() {
    const { currentPortfolio, updateData } = usePortfolio();

    const items = ref([]);
    // Chart
    const factChartData = ref([]);
    const updateChart = () => {
      factChartData.value = [];
      items.value.forEach(({ security_name: secName, curr_cost: curCost }) => {
        factChartData.value.push({
          name: secName,
          value: Math.round(curCost),
        });
      });
    };

    // Rebalancing items
    const loadItems = async () => {
      const response = await rebalancingItems(currentPortfolio.value.id);
      items.value = response;

      updateChart();
    };

    watch([currentPortfolio, updateData], () => {
      loadItems();
    });
    onMounted(loadItems);

    return {
      items,
      loadItems,
      factChartData,
    };
  },
};
</script>
