<template>
  <section id="portfolio-rebalancing">
    <rebalancing-info-card v-if="!isEdit" @edit="editData" />
    <rebalancing-edit-card v-else @save="saveData" @cancel="cancelEdit" />
  </section>
</template>

<script>
import RebalancingEditCard from "@/components/investments/rebalancing/RebalancingEditCard.vue";
import RebalancingInfoCard from "@/components/investments/rebalancing/RebalancingInfoCard.vue";

export default {
  name: "Rebalancing",
  components: {
    RebalancingEditCard,
    RebalancingInfoCard,
  },
  data() {
    return {
      isEdit: false,
    };
  },
  methods: {
    editData() {
      this.isEdit = true;
    },
    saveData() {
      this.cancelEdit();
    },
    cancelEdit() {
      this.isEdit = false;
    },
  },
};
</script>

<style></style>
