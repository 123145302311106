<template>
  <rebalancing-template>
    <template v-slot:chart>
      <echart-doughnut :dataSeries="groupChartData" :title="titleGroupChart" class="mb-1" />
      <hr />
      <echart-doughnut :dataSeries="assetsChartData" :title="titleAssetsChart" class="mb-1" />
      <hr />
      <echart-doughnut :dataSeries="sectorChartData" :title="titleSectorChart" class="mb-1" />
    </template>

    <template v-slot:header>
      <div class="mb-2 d-flex flex-wrap justify-content-between">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          @click="repeateAgain"
          size="sm"
        >
          <feather-icon icon="PlusIcon" class="mr-25" />
          <span>Добавить</span>
        </b-button>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :variant="disableButtonSave ? `secondary` : `success`"
            @click="save"
            size="sm"
            class="mx-1"
            :disabled="disableButtonSave"
          >
            <feather-icon icon="SaveIcon" class="mr-25" />
            <span>Сохранить</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-danger"
            @click="cancel"
            size="sm"
          >
            <feather-icon icon="XIcon" class="mr-25" />
            <span>Отменить</span>
          </b-button>
        </div>
      </div>
    </template>

    <template v-slot:default>
      <b-form ref="form" class="repeater-form" @submit.prevent="repeateAgain">
        <!-- Row Loop -->
        <b-row v-for="(item, index) in allocatedItems" :id="index" :key="index" ref="row">
          <!-- Security -->
          <b-col sm="7">
            <b-form-group label="Ценная бумага" label-for="security-name">
              <security-with-validation
                :id="`securityName_${index}`"
                :field_id="`${index}`"
                v-model="item.secName"
                vid="secName"
                label="Ценная бумага"
                :suggestions="securityList"
                @selected="selectSecurity"
                @input="getSecuritiesList"
                :label_name="`secName`"
                @focus="focusInput"
              />
            </b-form-group>
          </b-col>

          <!-- Share -->
          <b-col sm="3">
            <b-form-group label="Вес" label-for="share">
              <b-input-group append="%" size="sm" class="input-group-merge">
                <b-form-input id="share" type="number" v-model="item.share" />
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- Remove Button -->
          <b-col sm="2">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="flat-danger"
              class="mt-0 mt-sm-2 btn-icon"
              @click="removeItem(index)"
              size="sm"
            >
              <feather-icon icon="XIcon" />
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
      </b-form>
    </template>

    <template v-slot:footer>
      <template class="mb-1" v-if="unAllocatedItems.length != 0">
        <small>Нераспределенные активы</small>
        <div class="d-flex flex-wrap">
          <b-badge
            variant="light-secondary"
            v-for="item in unAllocatedItems"
            :id="item.secCode"
            :key="item.secCode"
            class="mr-1 mb-1 badge-pointer"
            v-b-tooltip.hover.v-secondary
            :title="item.secName"
            @click="addItem(item)"
          >
            {{ item.secCode }}
          </b-badge>
        </div>
      </template>
      <small>Доля всего: {{ totalShare }}%</small>
    </template>
  </rebalancing-template>
</template>

<script>
import { ref, watch } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import { useDebounceFn } from "@vueuse/core";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import RebalancingTemplate from "@/components/investments/rebalancing/RebalancingTemplate.vue";
import EchartDoughnut from "@/components/charts/EchartDoughnut.vue";
import SecurityWithValidation from "@/components/inputs/SecurityWithValidation.vue";
import usePortfolio from "@/comp-functions/usePortfolio";
import { secSearch, rebalancingItems, updateRebalancingItems } from "@/api/investments";

import EditRowClass from "@/models/EditRowClass";

export default {
  name: "RebalancingEditCard",
  directives: {
    Ripple,
  },
  components: {
    RebalancingTemplate,
    SecurityWithValidation,
    EchartDoughnut,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    totalShare() {
      return this.allocatedItems.reduce((sum, item) => sum + parseFloat(item.share), 0);
    },
    disableButtonSave() {
      if (this.allocatedItems.length === 0) return false;
      if (this.totalShare === 100) return false;
      return true;
    },
  },
  created() {
    // this.loadItems();
  },
  methods: {
    repeateAgain() {
      this.securityList = [];
      this.addItem();
    },
    removeItem(index) {
      this.allocatedItems.splice(index, 1);
      this.updateUnAllocatedItems();
    },
    focusInput(item) {
      this.securityList = [];
      this.selectFieldIdx = item.target.id;
    },
    selectSecurity(item) {
      this.securityList = [];

      // Присваиваем значения
      const objItem = this.allocatedItems[this.selectFieldIdx];

      this.$set(objItem, "secBoard", item.id);
      this.$set(objItem, "secCode", item.sec_code);
      this.$set(objItem, "secName", item.sec_name);
      this.$set(objItem, "secGroupName", item.sec_group_name);
      this.$set(objItem, "secSectorName", item.sector_name);

      this.selectFieldIdx = null;
      this.updateUnAllocatedItems();
    },
  },
  setup(props, { emit }) {
    const toast = useToast();
    const { currentPortfolio, updateData } = usePortfolio();

    const selectFieldIdx = ref(null);
    const nextTodoId = ref(0);

    const securityList = ref([]);
    const getSecuritiesList = useDebounceFn(async (text) => {
      if (!selectFieldIdx.value || text === "" || text === undefined) {
        return;
      }
      // TODO: Добавить is_open
      const response = await secSearch({
        pid: currentPortfolio.value.pid,
        secGroup: "",
        query: text,
      });

      securityList.value = [{ data: response }];
    }, 700);

    // Data
    const titleGroupChart = {
      text: "Группы активов",
      left: "center",
    };
    const titleAssetsChart = {
      text: "Активы",
      left: "center",
    };
    const titleSectorChart = {
      text: "Секторы",
      left: "center",
    };

    const allocatedItems = ref([]);
    const unAllocatedItems = ref([]);
    const itemsInPortfolio = ref([]);
    const assetsChartData = ref([]);
    const groupChartData = ref([]);
    const sectorChartData = ref([]);
    const groupingChart = (chartData) =>
      Array.from(
        chartData.reduce(
          (m, { name, value }) => m.set(name, (m.get(name) || 0) + value),
          new Map(),
        ),
        ([name, value]) => ({ name, value }),
      );
    const updateChart = () => {
      assetsChartData.value = [];
      groupChartData.value = [];
      sectorChartData.value = [];
      allocatedItems.value.forEach(({ secName, secGroupName, secSectorName, share }) => {
        assetsChartData.value.push({
          name: secName,
          value: Number(share),
        });
        groupChartData.value.push({
          name: secGroupName,
          value: Number(share),
        });
        sectorChartData.value.push({
          name: secSectorName || "Не указано",
          value: Number(share),
        });
      });
      groupChartData.value = groupingChart(groupChartData.value);
      sectorChartData.value = groupingChart(sectorChartData.value);
    };
    const loadItems = async () => {
      allocatedItems.value = [];
      unAllocatedItems.value = [];
      const response = await rebalancingItems(currentPortfolio.value.pid);
      response.forEach((element) => {
        const item = new EditRowClass();
        item.secCode = element.security_code;
        item.secName = element.security_name;
        item.secBoard = element.id;
        item.secGroupName = element.security_group_name;
        item.secSectorName = element.sector_name;
        item.share = element.target_share;

        if (element.cur_share > 0) {
          itemsInPortfolio.value.push(item);
        }
        if (item.share > 0) {
          nextTodoId.value += nextTodoId.value;
          item.id = nextTodoId.value;
          allocatedItems.value.push(item);
        } else {
          unAllocatedItems.value.push(item);
        }

        // if (item.share > 0) {
        //   allocatedItems.value.push({
        //     id: (nextTodoId.value += nextTodoId.value),
        //     ...item,
        //   });
        // } else {
        //   unAllocatedItems.value.push({
        //     ...item,
        //   });
        // }
      });
      // nextTodoId.value = allocatedItems.value.length;
    };
    const save = async () => {
      try {
        const data = [];
        allocatedItems.value.forEach((element) => {
          data.push({
            security_board: element.secBoard,
            share: element.share,
          });
        });
        await updateRebalancingItems(currentPortfolio.value.pid, data);
        emit("save");
      } catch (err) {
        console.error(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "Ошибка сохранения данных",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    };
    const updateUnAllocatedItems = () => {
      unAllocatedItems.value = itemsInPortfolio.value.filter(
        ({ secBoard: board1 }) =>
          !allocatedItems.value.some(({ secBoard: board2 }) => board1 === board2),
      );
      // TODO: Sort array
    };
    const cancel = async () => {
      emit("cancel");
    };
    const addItem = (security) => {
      nextTodoId.value += nextTodoId.value;

      const newRow = Object.assign(new EditRowClass(), security);
      newRow.id = nextTodoId.value;

      allocatedItems.value.push(newRow);
      updateUnAllocatedItems();
    };

    watch([currentPortfolio, updateData], () => {
      cancel();
    });

    watch(
      allocatedItems,
      () => {
        updateChart();
      },
      { deep: true },
    );

    return {
      securityList,
      getSecuritiesList,

      selectFieldIdx,
      allocatedItems,
      unAllocatedItems,
      loadItems,
      addItem,
      updateUnAllocatedItems,
      assetsChartData,
      groupChartData,
      sectorChartData,

      titleGroupChart,
      titleAssetsChart,
      titleSectorChart,

      save,
      cancel,
    };
  },
};
</script>

<style lang="scss" scoped>
.badge-pointer {
  cursor: pointer;
}
</style>
