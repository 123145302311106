<template>
  <b-row>
    <b-col cols="12" md="6">
      <b-card>
        <slot name="chart"></slot>
      </b-card>
    </b-col>
    <b-col cols="12" md="6">
      <b-card no-body>
        <b-card-body>
          <slot name="header"></slot>
          <slot></slot>
          <slot name="footer"></slot>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'RebalancingTemplate',
};
</script>

<style lang="scss" scoped>
.echarts {
  width: 100%;
  min-height: 300px;
}
</style>
