export default class EditRowClass {
  id = 0;

  secCode = null;

  secName = null;

  secBoard = null;

  secGroupName = null;

  secSectorName = null;

  share = 0;
}
